import React from "react";
import { basePath } from "@lib/utils/constants";

export const HeaderImage = () => {
  return (
    <div className="flex justify-center items-center mt-8">
      <div className="flex w-4/5 sm:w-1/2">
        <img src={`${basePath}/icons/header_image.svg`} className="w-full" />
      </div>
    </div>
  );
};
